<script>
import {mapGetters} from "vuex";
import SubordinatesTable from "../../../components/elements/user/subordinates-table.vue";
import SubordinatesPerformanceCard from "../../../components/elements/user/subordinates-performance-card.vue";
import ExportsCard from "../../../components/elements/exports-card.vue";

export default {
    name: "subordinates-dashboard",
    components: {ExportsCard, SubordinatesPerformanceCard, SubordinatesTable},

    computed: {
        ...mapGetters({
            authenticated: 'user/authenticated'
        }),

        year: function () {
            return new Date().getFullYear();
        },

        labelForRecursive: function () {
            return this.$t('organizations::positions.' + (this.recursive ? 'all_subordinates' : 'direct_subordinates')).ucFirst();
        },
    },

    data: function () {
        return {
            users: [],
            years: [],
            interactions: [],
            recursive: false
        }
    },

    methods: {
        fetch: function () {
            this.$store.dispatch('performance/subordinates', {id: this.authenticated?.id, recursive: this.recursive}).then(response => {
                this.users = response.users;
                this.interactions = response.interactions;
                this.years = response.years;
            });
        },
    },

    created() {
        this.fetch();
    },

    watch: {
        recursive: function () {
            this.fetch();
        },
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'users.subordinates'})
    },

    unmounted() {
        this.$store.dispatch('app/title', {key: ''})
    }
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        <span class="me-2">
                            <i class="fas fa-cogs"></i>
                        </span>
                        <span>
                            {{ $t('base.settings').ucFirst() }}
                        </span>
                    </h4>
                </div>
                <div class="card-body bg-light">
                    <b-input-group
                        class="d-flex align-items-center"
                    >
                        <label class="form-check-label me-2" for="recursive">
                            <span class="me-2">
                                <i class="fas" :class="{'fa-users': recursive, 'fa-user-friends': !recursive}"></i>
                            </span>
                            <span>{{ labelForRecursive }}</span>
                        </label>
                        <div class="form-check form-switch form-switch-md">
                            <input
                                id="recursive"
                                v-model="recursive"
                                class="form-check-input"
                                name="recursive"
                                type="checkbox"
                            />
                        </div>
                    </b-input-group>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">{{ year }} | {{ $t('projects::performance.yearly_performance').ucFirst() }}</h5>
                </div>
                <div class="card-body bg-light">
                    <subordinates-table></subordinates-table>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 col-lg-4" v-for="(user) in (users)" :key="user.id">
                    <subordinates-performance-card :user="user"></subordinates-performance-card>
                </div>
            </div>
        </div>
        <div class="col-12 mt-4">
            <exports-card></exports-card>
        </div>
    </div>
</template>

<style scoped>

</style>
